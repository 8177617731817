
import { pages } from './index';

export const routeList = [{
    path: '/alist_h5/showMarkDown',
    name: 'ShowMarkDown',
    element: <pages.ShowMarkDown/>,
    exact: true,
    //   auth: true,
    children: []
  },
  {
    path: '/alist_h5/declaration',
    name: 'Declaration',
    element:<pages.Declaration/>,
    exact: true,
    //   auth: true,
    children: []
  },
  {
    path: '/alist_h5/privacyPolicy',
    name: 'PolicyPrivacy',
    element: <pages.PolicyPrivacy />,
    exact: true,
    //   auth: true,
    children: []
  },
  {
    path: '/alist_h5/support',
    name: 'Support',
    element: <pages.Support />,
    exact: true,
    //   auth: true,
    children: []
  },
]