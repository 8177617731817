import { useRoutes } from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';
import { Provider } from './globalContext';
import { routeList } from './pages/routers';

function App() {
  const element = useRoutes(routeList);
  return (
    <>
      <Provider value={{}}>
        {element}
      </Provider>
    </>
  );
}

export default App;
