import axios from 'axios';
import jsonp from 'jsonp';
import { Toast } from 'antd-mobile'
import intl from 'react-intl-universal';
import EN from './locale/en.json';
import CH from './locale/zh.json';
import qs from 'qs';

// 配置国际化
const locales = {
    en_US: EN,
    zh_CN: CH
};
const { lang } = qs.parse(window.location.search.split('?')[1])
const language = lang === 'zh' ? 'zh_CN' :'en_US';
intl
  .init({
    warningHandler: (msg, detail) => { //注意该函数开发环境有，打包后就没有了
      console.log(`${msg} ${detail}`);
    },
    currentLocale: language, 
    locales
  })

// 配置axios

export const ajax =  axios.create()

ajax.interceptors.request.use(config => {
    if (config.method.toLowerCase() !== 'get') {
        config.headers['content-type'] = 'application/json';
        if (!config.data) {
            config.data = {};
        }
    }
    return config;
});

ajax.interceptors.response.use(response => {
    // let { code, msg } = response.data;
    // code = code === 200 ? 0 : code;
    if ((response.status !== 200)) {
        // message.error('请求发生错误');
        Toast.show({
            icon: 'fail',
            content: '请求发生错误',
        })
        return response;
    }

    return response;

});

ajax.jsonp = (url, options) => {
    return new Promise((resolve, reject) => {
        jsonp(url, options, (err, data) => {
            if (err) {
                resolve({ code: 500, msg: err })
            } else {
                resolve({ code: 0, data: data })
            }
        })
    })
}